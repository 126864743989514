.button {
  border: 2px solid var(--success-accent-color);
  border-radius: 10px;
  background-color: var(--success-color);
  color: var(--light-shade-color);
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.button:hover {
  background-color: var(--success-color-dark);
  /* border: 3px solid var(--info-color);
  cursor: not-allowed; */
}

.button:disabled {
  background-color: var(--dark-accent-color);
  border: 3px solid var(--info-color);
  cursor: not-allowed;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Arvo", serif;
}
