.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: var(--light-shade-color);
  justify-content: space-around;
}

.overlay {
  padding: 15px;
}

.overlayWithSubtitle {
  padding: 10px 15px 15px 15px;
}

.column {
  flex: 1;
  text-align: center;
  line-height: 2rem;
  padding: 1rem 0;
  margin: 1rem 8px;

  display: flex;
  flex-direction: column;
  position: relative;
}

.columnItem {
  background: var(--white);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 0;
  min-width: 50vw;
}

.contentOverlay {
  height: 30%;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: -65px;
  border-radius: 6px;
  background-color: var(--dark-shade-color);
  color: var(--light-shade-color);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.columnItemGreyAccent {
  composes: columnItem;
  background: var(--dark-accent-color);
  color: var(--light-shade-color);
  border-color: var(--dark-accent-color);
  display: flex;
  justify-content: center;
}

.columnItemBlackAccent {
  composes: columnItem;
  background: var(--dark-shade-color);
  color: var(--light-shade-color);
  border-color: var(--dark-shade-color);
  display: flex;
  justify-content: center;
}

.title {
  font-weight: 800;
  font-size: 1.75rem;
}

.subtitle {
  font-weight: 400;
  font-size: 1.25rem;
}

.doNotCall {
  padding-top: 1rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.labelWithButton {
  padding-bottom: 1rem;
}

.container > * {
  flex: 1;
  max-width: 80vw;
  min-width: min-content;
}

@media only screen and (min-width: 1280px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding: 1rem;
    background: var(--light-shade-color);
  }

  .overlay .overlayWithSubtitle {
    align-items: center;
  }

  .columnItem {
    flex-grow: 1;
    min-width: 15vw;
  }

  .contentOverlay {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .column {
    padding: 1rem 0;
    margin: 1rem 8px;
  }

  .column > * {
    flex: 1;
  }

  .container > * {
    flex: 1;
    max-width: 50vw;
    min-width: min-content;
  }
}

@media only screen and (min-width: 888px) and (max-width: 1279px) {
  .columnItem {
    min-width: 50vw;
  }
  .column {
    padding: 1rem 0;
    margin: 1rem 8px;
  }
  .container > * {
    flex: 1;
    max-width: 50vw;
    min-width: min-content;
  }
}

.overlaySmallText {
  margin: 50px 0;
}

.buttonTitle {
  font-size: 1.1rem;
  font-weight: 500;
}
