.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: var(--light-shade-color);
}

.content {
  height: calc(100% - 50px);
  width: 100vw;
  padding-top: 20px;
}

/* unvisited link */
a:link {
  color: var(--success-color);
}

/* visited link */
a:visited {
  color: var(--warning-color);
}

/* mouse over link */
a:hover {
  color: var(--primary-color);
}

/* selected link */
a:active {
  color: blue;
}

h3 {
  text-align: left;
  font-size: 21px;
}

.alert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 50vh;
  font-size: 19px;
  line-height: 30px;
  padding: 20px;
  margin-top: 50px;
}

@media only screen and (min-width: 1280px) {
  .content {
    padding-top: 30px;
    width: 1280px;
  }

  .alert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    height: 300px;
    font-size: 19px;
    line-height: 30px;
    padding: 20px;
    margin-top: 50px;
  }
}

.fileIFrame {
  width: 100%;
  height: 700px;
}

@media only screen and (min-width: 600px) {
  .fileIFrame {
    width: 90vw;
    height: 80vh;
  }
}
