:root {
  --primary-color: #003366;
  --primary-bright-color: #fcba19;
  --light-shade-color: #f2f2f2;
  --white: #ffffff;
  --light-accent-color: #707072;
  --dark-accent-color: #707072;
  --dark-shade-color: #38598a;

  --info-color: #1a1c1b;
  --success-color-dark: #18461a;
  --success-color: #2e7d32;
  --success-accent-color: #83c086;
  --warning-color: #d68824;
  --error-color: #f44336;
  --black-color: #090808;
}
