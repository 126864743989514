.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}
