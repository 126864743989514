.header {
  z-index: 100;
  font-family: "Arvo", serif;
  position: sticky;
  /* top: 0; */
  background-color: var(--primary-color);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f7f5f5;
  overflow: hidden;

  border-bottom: 3px solid var(--primary-bright-color);

  width: 100%;
  padding: 10px 0;
}

.logo {
  width: 110px;
  pointer-events: none;
  height: auto;
}

@media only screen and (min-width: 1133px) {
  .header {
    padding: 20px 0;
  }

  .logo {
    width: 200px;
  }
}
