.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: none;
  background-color: #343434;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.dialog {
  z-index: 100;
}

.alert {
  z-index: 100;
  top: 20%;
  /* position: relative; */
  backdrop-filter: blur(50px);
}

.alert::backdrop {
  background-color: aqua;
}

.dialog::backdrop {
  background-color: aqua;
}
